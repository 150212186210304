import { Loader } from "@googlemaps/js-api-loader";
class MapBlockSimple {
    constructor(wrapper, apiKey, style, lat, lng, markerIcon = "https://maps.google.com/mapfiles/kml/paddle/red-circle.png") {
        this.marker = null;
        this.zoom = 12;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.map = null;
        this.style = "";
        this.wrapper = wrapper;
        this.apiKey = apiKey;
        this.markerIcon = markerIcon;
        this.style = style;
        this.centre = {
            lat,
            lng,
        };
        this.initMap();
    }
    static create(wrapper, apiKey, style, lat, lng, markerIcon) {
        return new MapBlockSimple(wrapper, apiKey, style, lat, lng, markerIcon);
    }
    initMap() {
        const loader = new Loader({
            apiKey: this.apiKey,
            version: "weekly",
            libraries: ["maps", "marker"],
        });
        console.log("[MapBlock.ts] styles: ", JSON.parse(this.style));
        loader
            .load()
            .then((google) => {
            this.map = new google.maps.Map(this.wrapper, {
                center: this.centre,
                zoom: this.zoom,
                // mapId: "based", // ... maybe replace with a real mapId
                styles: JSON.parse(this.style),
            });
            console.log("[MapBlock.ts] marker: ", this.marker);
        })
            .then(() => {
            const marker = new google.maps.Marker({
                position: this.centre,
                map: this.map,
                icon: {
                    url: this.markerIcon,
                    size: new google.maps.Size(64, 76),
                    scaledSize: new google.maps.Size(64, 76),
                },
            });
            this.marker = marker;
        })
            .catch((e) => {
            throw new Error(e);
        });
    }
}
export default MapBlockSimple;
